import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
  QueryCache,
  ReactQueryCacheProvider,
  ReactQueryConfigProvider
} from 'react-query'
import React from 'react'
import { BanquetLoader } from 'banquet-runtime-modules'
import { useLocation } from 'react-use'
import {
  BrandedMobileBackground,
  CloseButton,
  DesktopHeader,
  HeaderOverlay,
  HomeLink,
  MobileHeader,
  MobileMenuToggle
} from '@toasttab/buffet-pui-global-navigation'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import HomePage from '../pages/HomePage'
import { LocalJWTManager } from '@local/jwt-manager'
import { RepositoryListContainer } from '../components/RepositoryListContainer'
import { GitStatusIndicator } from '../components/GitStatusIndicator'
import { RepoDetailsPage, RepoDetailsTabs } from '../pages/RepoDetailsPage'
import { datadogLogs } from '@datadog/browser-logs'

import classnames from 'classnames/bind'
import styles from './App.module.css'

const cx = classnames.bind(styles)

const jwtManager = new LocalJWTManager()

const queryCache = new QueryCache()
const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if ([401, 403].includes(error.status)) {
        return false
      }
      return failureCount < 3
    }
  }
}

function Overlay({ isOpen, onClose, children }) {
  return (
    <HeaderOverlay isOpen={isOpen} className='fixed top-0 z-20 w-full bg-white'>
      <div className='flex'>
        <div className='px-2'>
          <CloseButton onClick={onClose} />
        </div>
      </div>
      {children}
    </HeaderOverlay>
  )
}

const App = () => {
  const size = useScreenSize()

  const { pathname } = useLocation()
  const [mobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)

  React.useEffect(() => {
    // Start each new path with the mobile menu closed
    setIsMobileMenuOpen(false)
  }, [pathname])

  if (jwtManager.isExpired()) {
    jwtManager.refresh()
  }
  const username = jwtManager.getUserName()

  datadogLogs.init({
    clientToken: 'pubcb4ce52008249d5ac58638fe3f9bc476',
    service: 'tadpole',
  })

  datadogLogs.logger.info(`User: ${username}`, { user: username })

  const basename = window.location.pathname.startsWith('/node')
    ? '/node'
    : undefined

  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <div className={cx('layout-wrapper')}>
          <Router basename={basename}>
            <Route path='/:repoName?'>
              <div data-tdp-list className={cx('tdp--header')}>
                {size >= ScreenSize.MD ? (
                  <DesktopHeader isOpen>
                    <img
                      height={32}
                      width={32}
                      src='https://cdn.toasttab.com/static/f6f13400bf1aa60334bc67215844fede6bdc5e81/projects/tadpole/logo.png'
                      alt='tadpole spa versions'
                    />
                    <div className='pr-3 lg:pr-4 type-headline-4 text-success'>
                      tadpole
                    </div>
                    <RepoDetailsTabs />
                    <GitStatusIndicator />
                  </DesktopHeader>
                ) : (
                  <>
                    <MobileHeader>
                      <BrandedMobileBackground>
                        <div className='flex'>
                          <HomeLink />
                          <MobileMenuToggle
                            onClick={() => setIsMobileMenuOpen(true)}
                          />
                          <div className='py-2.5 px-2 text-light type-headline-5'>
                            tadpole
                          </div>
                        </div>
                      </BrandedMobileBackground>
                    </MobileHeader>
                    <Overlay
                      isOpen={mobileMenuOpen}
                      onClose={() => setIsMobileMenuOpen(false)}
                    >
                      <RepositoryListContainer className={cx('tdp--nav')} />
                    </Overlay>
                  </>
                )}
              </div>
            </Route>
            <Route path='/:repoName?/:view?'>
              {size >= ScreenSize.MD && (
                <RepositoryListContainer className={cx('tdp--nav')} />
              )}
            </Route>
            <Route exact path='/create'>
              <BanquetLoader name='dx-lilypad' />
            </Route>
            <Route exact path='/migrate'>
              <BanquetLoader name='lilypad-migrate' />
            </Route>
            <Route exact path='/destroy'>
              <BanquetLoader name='dobby-destroy-form' />
            </Route>
            <Route exact path='/deploy'>
              <BanquetLoader name='dobby-deploy-form' />
            </Route>
            <div id='contentContainer' className={cx('tdp--main-content')}>
              <div className='w-full'>
                <Switch>
                  <Route exact path='/'>
                    <HomePage username={username} />
                  </Route>
                </Switch>
                <Route path={`/:repoName`}>
                  <RepoDetailsPage username={username} size={size} />
                </Route>
              </div>
            </div>
          </Router>
        </div>
      </ReactQueryCacheProvider>
    </ReactQueryConfigProvider>
  )
}

export default App
